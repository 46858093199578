import {register} from "swiper/element/bundle";
import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SwiperComponent implements AfterViewInit {
  @Input()
  swiperId = '';
  @Input()
  paginationType = 'fraction'

  ngAfterViewInit(): void {
    register();

    const params = {
      injectStyles: [
        '.swiper-pagination-bullet {width: 14px !important;height: 14px !important;background: #B3C0F4 !important; opacity: 1 !important;}',
        '.swiper-pagination-bullet-active {width: 14px !important;height: 14px !important;background: #2E50E0 !important;border: opacity: 1 !important;}',
        '.swiper-button-next, .swiper-button-prev {color: #08123A !important;}',
        '.swiper-pagination.swiper-pagination-hidden {opacity: 1}',
        '@media screen and (max-width: 599px) {.swiper-button-next, .swiper-button-prev {color: #B3C0F4 !important;opacity: .1;}}'
      ],
      autoplay: {
        delay: 30000
      },
      threshold: 30,
      grabCursor: true,
      pagination: {
        type: this.paginationType,
        clickable: true,
        hideOnClick: true,
      }
    };

    if (this.swiperId) {
      const container: any = document.getElementById(this.swiperId);
      if (container) {
        Object.assign(container, params);
        container.initialize();
      }
    } else {
      document.querySelectorAll('swiper-container')
        .forEach((container: any) => {
          Object.assign(container, params);
          container.initialize();
        })
    }
  }
}
